// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-projects-design-js": () => import("./../../../src/pages/projects/design.js" /* webpackChunkName: "component---src-pages-projects-design-js" */),
  "component---src-pages-projects-performance-js": () => import("./../../../src/pages/projects/performance.js" /* webpackChunkName: "component---src-pages-projects-performance-js" */),
  "component---src-pages-projects-theatre-making-js": () => import("./../../../src/pages/projects/theatre-making.js" /* webpackChunkName: "component---src-pages-projects-theatre-making-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

